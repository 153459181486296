.box{
  max-width:1920px;
  min-width: 900px;
  height: 736px;
  background-repeat: no-repeat;
  animation: change-background 60s linear infinite ;

}
@keyframes change-background {
  0% {
    background-image: url("images/banner1.png")
  }
  25% {
    background-image: url("images/banner2.png")
  }
  50% {
    background-image: url("images/banner3.png")
  }
  75% {
    background-image: url("images/banner4.png")
  }
  100% {
    background-image: url("images/banner5.png")
  }
}


.box__contact{
  margin: 10px 5px 10px 0;
  display: flex;
}

.box__nav_contact{
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.box__header{
  display: flex;
  justify-content: space-between;
}

.nav {
  border-radius: 10px;
  background-color: rgb(251, 211, 65);
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 760px;
  height: 44px;
  .nav__items{
    font-size: 18px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    line-height: 1.667;
    text-align: left;
  }
  a{
    text-decoration: none;
  }
}

.street {
  margin-right: 25px;
  font-size: 14px;
  color: rgba(243, 243, 243, 0.8);
  font-weight: bold;
  line-height: 2.143;
  width: 255px;
}
.contact {
  margin-right: 40px;
  font-size: 14px;
  color: rgba(243, 243, 243, 0.8);
  font-weight: bold;
  line-height: 2.143;
  text-align: left;
  top: 10px;
  width: 271px;
}

.logo__main{
  margin: 5px 0 0 15px;
}

.logo__social_facebook{
  margin-right: 10px;
}


.area {
  background-image: linear-gradient( -180deg, rgb(15,37,71) 0%,  rgba(25,44,74,0.32) 68%, rgba(34,51,77,0) 100%) ;
  background-image: -webkit-linear-gradient( -180deg, rgb(15,37,71) 0%, rgba(25,44,74,0.32) 68%, rgba(34,51,77,0) 100%);
  background-image: -ms-linear-gradient( -180deg, rgb(15,37,71) 0%, rgba(25,44,74,0.32) 68%, rgba(34,51,77,0) 100%);
  width: 70%;
  height: 228px;
  margin: 235px 0 0 0;
  padding-top: 2%;
  padding-right: 30px;


  .text {
    margin: 0 0 0 25px;
    font-size: 30px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    line-height: 1;
    height: 28px;

  }
  .button{
    border-radius: 10px;
    background-color: rgb(251, 211, 65);
    font-size: 18px;
    color: rgb(0, 0, 0);
    line-height: 1.667;
    margin:45px 0 0 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 254px;
    height: 48px;
  }
}

.box__pagination{
  margin: 110px 0 0 15px;
  display: flex;


}

.dropdown {
  float: left;
  overflow: hidden;

}

.dropdown .dropbtn {
  font-size: 18px;
  font-weight: bold;
  border: none;
  outline: none;
  color: #000000;
  padding: 14px 16px;
  background-color: inherit;

  margin: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgb(51, 151, 226, 0.5);
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: #dddddd;
  padding: 6px 10px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {background-color: rgba(28, 134, 224, 0.5);}
.dropdown:hover .dropdown-content {
  display: block;
}
