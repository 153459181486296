.container{
  margin-top: 75px;
  background-color: rgb(248, 247, 247);
  max-width: 1920px;
  min-width: 900px;
  height: 100%;
  padding-top: 45px;
}
 @media (max-width: 966px) {
   .helper{
      flex-direction: column;
     align-items: center;
     margin-left: 50px;
   }
  .text__benefits{
    text-align: center;
  }
}

.title{
  margin: 0 0 60px 17px;
    font-size: 24px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    line-height: 1.25;
}


.image__benefits {
  margin: 0 90px 0 15px;
  width: 460px;
  height: 315px;
}

.text__benefits{
  margin: 0 70px 0 0;
  font-size: 16px;
  color: rgb(0, 0, 0);
  line-height: 1.5;
  display: flex;
  align-items: center;
}

.helper{
  display: flex;
  padding-bottom: 90px;

}