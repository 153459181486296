.box__card{
    border-radius: 7px;
    background-color: rgb(248, 247, 247);
    width: 519px;
    height: 202px;
    display: flex;
    flex-direction: row;
    position: relative;
}
.image {
    width: 185px;
    height: 175px;
    margin: 15px 30px 15px 15px;
}

.card__text{
    display: flex;
    flex-direction: column;
}

.card__text_main{
    margin: 25px 0 0 0;
        font-size: 17px;
        color: rgb(0, 0, 0);
        font-weight: bold;
        line-height: 1.765;
        text-align: left;
}

.card__text_time{
    margin-bottom: 10px;
    font-size: 14px;
    color: rgb(58, 58, 58);
    line-height: 1.2;
    text-align: left;
}

.line {
    margin-bottom: 17px;
    background-color: rgb(106, 106, 106);
    opacity: 0.102;
    width: 270px;
    height: 1px;

}

.card__text_info {
    margin-bottom: 30px;
    font-size: 14px;
    color: rgb(58, 58, 58);
    line-height: 1.2;
    text-align: left;

}

.card__text_price {
    font-size: 20px;
    color: rgb(0, 0, 0);
    line-height: 1.2;
    text-align: left;
}


.button__card {
    border-radius: 50%;
    background-color: rgb(251, 211, 65);
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 37px;
    height: 37px;
}

.card__text_person {
    font-size: 11px;
    color: rgb(138, 138, 138);
    line-height: 1.2;
    text-align: left;
    position: absolute;
    left: 323px;
    top: 163px;
    width: 85px;
}

