.footer__container {
  background-color: rgb(34, 51, 77);
  max-width: 1920px;
  min-width: 900px;
  height: 310px;
  display: flex;
}

.footer__image{
  margin: 50px 0 0 17px;
}

.footer__line {
  margin: 30px 0 24px 17px;
  background-color: rgb(247, 248, 249);
  opacity: 0.102;
  width: 293px;
  height: 1px;
}
.footer__line_contact {
  margin: 20px 0 15px 0;
  background-color: rgb(247, 248, 249);
  opacity: 0.102;
  width: 255px;
  height: 1px;
}

.footer__text{
  margin: 0 0 0 17px;
    font-size: 14px;
    color: rgb(255, 255, 255);
    line-height: 1.714;
    text-align: left;
    width: 311px;
    height: 61px;
}

.footer__creator {
  margin: 0 0 0 17px;
  font-size: 14px;
  color: rgb(194, 194, 194);
  line-height: 1.714;
  text-align: left;
  height: 13px;
}


.footer__content{
  margin: 0 auto 0 auto;
  display: flex;
}



.footer__navigation{
  margin: 50px auto 0 125px;
  font-size: 18px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 1.333;
  text-align: left;
}

.footer__support{
  margin: 50px auto 0 125px;
  font-size: 18px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 1.333;
  text-align: left;
}

.footer__contact{
  margin: 50px auto 0 125px;
  font-size: 18px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 1.333;
  text-align: left;
}
.footer__navigation_items {
    font-size: 14px;
    color: rgb(255, 255, 255);
    line-height: 2.143;
    text-align: left;
}
.footer__support_items{
  font-size: 14px;
  color: rgb(255, 255, 255);
  line-height: 2.143;
  text-align: left;}
.footer__contact_items{
  color: rgb(255, 255, 255);
  line-height: 2.143;
  text-align: left;}

.logo__facebook{
  margin: 0 15px 0 0;
  opacity: 0.8;


}
.logo__instagram{
  opacity: 0.8;
}


@media (max-width: 1275px) {
  .footer__navigation{
    margin: 50px auto 0 25px;
  }
  .footer__support{
    margin: 50px auto 0 25px;
  }
  .footer__contact{
    margin: 50px auto 0 25px;
  }
}