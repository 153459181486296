.proposal__title {
  margin:0 0 35px 17px;
  font-size: 24px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 1.25;
  text-align: left;
}

.proposal__container {
  background-image: url("proposal.png");
  padding-top: 48px;
  max-width: 1920px;
  min-width: 900px;
  padding-bottom: 85px;
}

.proposal__box {
  background-color: rgb(0, 0, 0);
  margin: 0 auto 0 auto;
  width: 90%;
  height: 131px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.proposal__box_button {
  font-size: 18px;
  border-radius: 10px;
  background-color: rgb(251, 211, 65);
  width: 254px;
  height: 48px;

}


.proposal__box_items {
  font-size: 18px;
  color: rgb(229, 229, 229);
  line-height: 1.667;
  text-align: left;

}


.dropdown1 {
  float: left;
  overflow: hidden;

}


.dropdown1 .dropbtn1 {
  font-size: 18px;
  font-weight: bold;
  border: none;
  outline: none;
  color: #ffffff;
  padding: 6px 10px;
  background-color: inherit;
  margin: 0;
}



.dropdown-content1 {
  display: none;
  position: absolute;
  background-color: rgba(255, 255, 255);
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content1 a {
  float: none;
  color: #000000;
  padding: 6px 10px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content1 a:hover {color: #f2cb43;}
.dropdown1:hover .dropdown-content1 {
  display: block;
}




.form__group {
  position: relative;
  padding: 30px 0 0;
  width: 10%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #9b9b9b;
  outline: 0;
  font-size: 14px;
  color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 18px;
    font-weight: bold;
    cursor: text;
    top: 20px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 18px;

  color: #ffffff;
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 14px;
    color: #ffffff;
    font-weight:700;
  }
  padding-bottom: 6px;
  border-width: 3px;
}
.form__field {
  &:required, &:invalid {
    box-shadow: none;
  }
}